.MuiTableCell-root {
  padding: 10px !important;
}

/* 
.MuiTableCell-body {
  padding-right: 8px;
} */
/* .ui.inverted.menu {
  background: #902d8c !important;
} */

/* .ui.menu.inverted {
  background-color: #6699A1 !important;
}

*/

.ui.menu .text:hover,
.ui.menu .item:hover {
  color: #cd9806 !important;
}

.ui.menu .ui.dropdown .menu>.item:hover {
  color: #cd9806 !important;
}

/* div.ui.header {
  color: #2e7997 !important;
} */

.ui.menu {
  margin: 0;
}

/* .ui.menu a:link{
  color:#902d8c !important;
} */
/* .ui.menu .header.item{
  color:#902d8c !important;
} */

.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.botton-support {
  border-radius: 8px;
  border: 5px solid transparent;
  background-clip: border-box !important;
  background-origin: border-box !important;
  background: linear-gradient(135deg, #e6c257, #dba912);
  color: #fff;
}

.MuiTableBody-root .MuiFormControl-root {
  width: 96%;
}

.h-90 {
  height: 90% !important
}

.w-90 {
  width: 90% !important
}

.table .form-check label .form-check-sign::before,
.table .form-check label .form-check-sign::after,
.table .checkbox label:before,
.table .checkbox label:after {
  top: 0px !important
}

.section {
  padding: 0px !important
}

/* #cd-vertical-nav {
  right: -55px !important
} */

.ck-editor__editable {
  min-height: 500px;
}

/* .ck-content */
.image.image_resized {
  display: block;
  box-sizing: border-box;
}

/* .ck-content */
.image.image_resized img {
  width: 100%;
}

/* .ck-content */
.image.image_resized>figcaption {
  display: block;
}
.image img{
  max-width: 100%;
}
.image{
  max-width: 100%;
}

/* //// */
.MuiTableCell-body{
  overflow-wrap: anywhere;
}